import React, { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Editor } from '@tinymce/tinymce-react';
import Alert from '@mui/material/Alert';
import CheckIcon from '@mui/icons-material/Check';
import BarheaderAdmin from '../BarheaderAdmin';
import NavBarAdmin from '../NavBarAdmin';
import "./css/EmailingPub.css";
import DOMPurify from 'dompurify';
import { sendEmail } from '../../../Redux/Slice/emailSlice';

const EmailingPub = () => {
  const { Lists } = useSelector((state) => state.list);
  const [emailMessage, setEmailMessage] = useState("");
  const [subject, setSubject] = useState("");
  const [showAlert, setShowAlert] = useState(false);
  const editorRef = useRef(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    return () => {
      if (editorRef.current) {
        editorRef.current.destroy();
      }
    };
  }, []);



  const handleEditorChange = (content, editor) => {
    setEmailMessage(content);
  };

  const handleSendEmail = (e) => {
    e.preventDefault();
    const selectedListEmails = Lists.map((List) => List.mail);
    const cleanedHtml = DOMPurify.sanitize(emailMessage);

    dispatch(sendEmail({ email: selectedListEmails, subject: subject, message: cleanedHtml }));

    // Display the alert
    setShowAlert(true);

    // Hide the alert and navigate after a delay
    setTimeout(() => {
      setShowAlert(false);
      navigate('/admin/atelier-A');
    }, 3000); // 3-second delay before navigating
  };

  return (
    <>
      <BarheaderAdmin />
      <NavBarAdmin />

      {/* Full-width alert at the top */}
      <div style={{ width: '100%', position: 'fixed', top: 0, left: 0, zIndex: 1200 }}>
        {showAlert && (
          <Alert icon={<CheckIcon fontSize="inherit" />} severity="success" style={{ marginBottom: '20px', width: '100%' }}>
            Email envoyé avec succès !
          </Alert>
        )}
      </div>

      <div className="ConsultEmail" style={{ paddingTop: showAlert ? '70px' : '20px' }}>
        <div className="ConsultEmailContainer">
          <h3 className="EmailingC">Emailing</h3>
          <label>Objet:</label>
          <input type="text" value={subject} onChange={(e) => setSubject(e.target.value)} />
          <label>Message:</label>
          <Editor
            apiKey="1994z08ifihaxvil1djjswb8ukpzno8v15iflre6tzcdv7g8"
            onInit={(evt, editor) => {
              editorRef.current = editor;
            }}
            initialValue={emailMessage}
            init={{
              height: 500,
              menubar: false,
              plugins: [
                'advlist', 'autolink', 'lists', 'link', 'image', 'charmap', 'preview',
                'anchor', 'searchreplace', 'visualblocks', 'code', 'fullscreen',
                'insertdatetime', 'media', 'table', 'code', 'help', 'wordcount'
              ],
              toolbar: 'undo redo | formatselect | bold italic forecolor | alignleft aligncenter alignright alignjustify | ' +
                'bullist numlist outdent indent | removeformat | help',
              content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
              setup: (editor) => {
                editor.on('change', () => handleEditorChange(editor.getContent(), editor));
              }
            }}
          />
          <button onClick={handleSendEmail} className="SendEmail">Envoyer</button>
        </div>
      </div>
    </>
  );
}

export default EmailingPub;
