import React, { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { Editor } from '@tinymce/tinymce-react';
import BarheaderAdmin from '../BarheaderAdmin.js';
import NavBarAdmin from '../NavBarAdmin.js';
import './css/AddPub.css';
import { AddPublic } from '../../../Redux/Slice/PubAtelierSlice.js';
import OverlayA from '../OverlayA.js';

const AddPublication = () => {
    const [titre, setTitre] = useState('');
    const [texte, setTexte] = useState('');
    const [photo, setPhoto] = useState(null);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const editorRef = useRef(null);
    const { id } = useParams();

    useEffect(() => {
        return () => {
            if (editorRef.current) {
                editorRef.current.destroy();
                editorRef.current = null;
            }
        };
    }, []);

    const handleTitreChange = (e) => {
        setTitre(e.target.value);
    };

    const handleEditorChange = (content) => {
        setTexte(content);
    };

    const handlePhotoChange = (e) => {
        setPhoto(e.target.files[0]);
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        const formDataToSend = new FormData();
        formDataToSend.append('titre', titre);
        formDataToSend.append('texte', texte);
        formDataToSend.append('img', photo);

        dispatch(AddPublic({ id, data: formDataToSend }))
            navigate('/admin/atelier-A')
        
    };
    const handelAccueil = () => {
        navigate("/admin/Accueil");
      };
    return (
        <>
            <BarheaderAdmin />
            <NavBarAdmin />
         <OverlayA/>
            <div className="addPub">
           <div       className="addPubContainer"
           >
           <button className="buttonAccueil" onClick={handelAccueil}>
            Accueil
          </button>
                <form onSubmit={handleSubmit} >
                    <div style={{display:'flex',flexDirection:'column'}}>
                        <label className='LabelAddPub'>Titre:</label>
                        <input type="text"  className="inputAddPubl" value={titre} onChange={handleTitreChange} required />
                    </div>
                    <div style={{display:'flex',flexDirection:'column'}}>
                        <label className='LabelAddPub'>Texte:</label>
                        <Editor
                            apiKey="1994z08ifihaxvil1djjswb8ukpzno8v15iflre6tzcdv7g8"
                            onInit={(evt, editor) => {
                                editorRef.current = editor;
                                editor.setContent(texte);
                            }}
                            initialValue={texte}
                            init={{
                                height: 500,
                                menubar: false,
                                plugins: [
                                    'advlist', 'autolink', 'lists', 'link', 'image', 'charmap', 'preview',
                                    'anchor', 'searchreplace', 'visualblocks', 'code', 'fullscreen',
                                    'insertdatetime', 'media', 'table', 'code', 'help', 'wordcount'
                                ],
                                toolbar: 'undo redo | blocks | ' +
                                    'bold italic forecolor | alignleft aligncenter ' +
                                    'alignright alignjustify | bullist numlist outdent indent | ' +
                                    'removeformat | help',
                                content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
                                setup: (editor) => {
                                    editor.on('change', () => handleEditorChange(editor.getContent()));
                                }
                            }}
                            className="editorPub"
                        />
                    </div>
                    <div style={{display:'flex',flexDirection:'column'}}>
                        <label className='LabelAtelier'>Photo:</label>
                        <input type="file"  onChange={handlePhotoChange} required />
                    </div>
                    <div className='button-Container'>
                        <button type="submit" className='btnAddPub'>Ajouter</button>
                        <button type="button" className='btnAnnulerAdd' onClick={() => navigate("/admin/atelier-A")}>Annuler</button>
                    </div>
                </form>
           </div>
            </div>
        </>
    );
};

export default AddPublication;
