import React, { useEffect, useRef, useState } from "react";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import image1 from "../../images/big_image_2.jpg";
import { BASE_URL_back, getImageUrl } from "../..";
import { AddEvenement, GetEvenement } from "../../Redux/Slice/EvenementSlice";
import { Evnt_OG } from "../../Redux/Slice/EvenementSlice.js";
import { useDispatch, useSelector } from "react-redux";
import "./css/ajouterEvnt.css";
import { CiCalendarDate } from "react-icons/ci";
import { Dialog, DialogContent, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import logo from "../../images/logo.jpg";
import { MdGpsFixed } from "react-icons/md";
import { FacebookShareButton, LinkedinShareButton } from "react-share";
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import FacebookIcon from '@mui/icons-material/Facebook';
import Newsletter from "../coach/Newsletter";
import Footer from "../coach/Footer";
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet-async";

const AjouterEvtt = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { Evenement } = useSelector((state) => state.evenement);
  const [showModal, setShowModal] = useState(false);
  const [selectedEvent, setSelectedEvent] = useState(null);
  const [Texte, setTexte] = useState("");
  const [image, setimage] = useState("");
  const [randomEvnt, setRandomEvnt] = useState([]);

  const photoRef = useRef(null);

  const [formData, setFormData] = useState({
    titre: "",
    texte: "",
    lien: "",
    lieu: "",
    dates: "",
  });
  const [errors, setErrors] = useState({});

  useEffect(() => {
    dispatch(GetEvenement());
  }, [dispatch]);

  useEffect(() => {
    if (Evenement.length > 0 && randomEvnt.length === 0) {
      const shuffledEvnt = Evenement
        .slice()
        .sort(() => Math.random() - 0.5)
        .slice(0, 4);
      setRandomEvnt(shuffledEvnt);
    }
  }, [Evenement]);

  useEffect(() => {
    if (selectedEvent) {
      dispatch(Evnt_OG(selectedEvent._id));
    }
  }, [selectedEvent, dispatch]);

  const validateForm = () => {
    const newErrors = {};

    if (!formData.titre) {
      newErrors.titre = "Veuillez renseigner ce champ.";
    }
    if (!Texte) {
      newErrors.Texte = "Veuillez renseigner ce champ.";
    }
    if (!formData.lieu) {
      newErrors.lieu = "Veuillez renseigner ce champ.";
    }
    if (!formData.lien) {
      newErrors.lien = "Veuillez renseigner ce champ.";
    }
    if (!formData.dates) {
      newErrors.dates = "Veuillez renseigner ce champ.";
    }
    if (!image) {
      newErrors.image = "Veuillez ajouter une photo.";
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleTitleClick = (article) => {
    setSelectedEvent(article);
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
    setSelectedEvent(null);
  };

  const handleFileChange = (e) => {
    setimage(e.target.files[0]);
  };

  const handleEditorChange = (event, editor) => {
    const data = editor.getData();
    setTexte(data);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!validateForm()) {
      return;
    }

    const Titre = formData.titre;
    const Lien = formData.lien;
    const Lieu = formData.lieu;
    const Dates = formData.dates;
    const Photo = photoRef.current.files[0];

    const formDataToSend = new FormData();
    formDataToSend.append("titre", Titre);
    formDataToSend.append("texte", Texte);
    formDataToSend.append("lien", Lien);
    formDataToSend.append("lieu", Lieu);
    formDataToSend.append("dates", Dates);
    formDataToSend.append("photo", Photo);

    dispatch(AddEvenement(formDataToSend));
    navigate("/Evenement");
  };

  const shareURL = selectedEvent ? `${BASE_URL_back}/Evenement/${selectedEvent._id}` : '';

  return (
    <>
      <div
        className="ImagePlatformeEvnAdd section-hero"
        style={{
          position: "relative",
          textAlign: "center",
          height: "300px",
          backgroundImage: `url(${image1})`,
          backgroundSize: "cover",
          overflow: "hidden",
        }}
      >
        <h3 className="AddEvntTitre">Partagez votre évènement</h3>
      </div>
      <div className="AjouterEVNT">
        <div className="ContainerEvnt">
          <div className="left-Evnt">
            {randomEvnt.map((article, index) => (
              <div key={index} className="Evnt-Item">
                <img
                  className="left-Evnt-img"
                  src={getImageUrl(article.photo)}
                  alt="Article"
                />
                <h1
                  className="EvntTitre"
                  onClick={() => handleTitleClick(article)}
                >
                  {article.titre}
                </h1>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <CiCalendarDate className="EvntDate" />
                  <h3 className="EvntDate">{article.dates}</h3>
                </div>
              </div>
            ))}
          </div>
          <div className="right-Evnt">
            <h1 className="formulaireEvnt">
              Pour partager un évènement, cet espace est pour vous !
            </h1>
            <form onSubmit={handleSubmit}>
              <label className="LabelEvnt">Titre:</label>
              <input
                type="text"
                className="InputEvnt"
                name="titre"
                required
                value={formData.titre}
                onChange={(e) => setFormData({ ...formData, titre: e.target.value })}
              />
              {errors.titre && <p className="error-message">{errors.titre}</p>}

              <label className="LabelEvnt">Texte:</label>
              <CKEditor
                editor={ClassicEditor}
                data={Texte}
                onChange={handleEditorChange}
              />
              {errors.Texte && <p className="error-message">{errors.Texte}</p>}

              <label className="LabelEvnt">Lien:</label>
              <input
                type="text"
                className="InputEvnt"
                name="lien"
                required
                value={formData.lien}
                onChange={(e) => setFormData({ ...formData, lien: e.target.value })}
              />
              {errors.lien && <p className="error-message">{errors.lien}</p>}

              <label className="LabelEvnt">Lieu:</label>
              <input
                type="text"
                className="InputEvnt"
                name="lieu"
                required
                value={formData.lieu}
                onChange={(e) => setFormData({ ...formData, lieu: e.target.value })}
              />
              {errors.lieu && <p className="error-message">{errors.lieu}</p>}

              <label className="LabelEvnt">Date:</label>
              <input
                type="text"
                className="InputEvnt"
                name="dates"
                required
                value={formData.dates}
                onChange={(e) => setFormData({ ...formData, dates: e.target.value })}
              />
              {errors.dates && <p className="error-message">{errors.dates}</p>}

              <label className="LabelEvnt">Photo:</label>
              <input
                type="file"
                name="photo"
                onChange={handleFileChange}
                ref={photoRef}
              />
              {errors.image && <p className="error-message">{errors.image}</p>}

              <button type="submit" className="AddEvnt">
                Envoyer
              </button>
            </form>
          </div>
        </div>
      </div>
      <Dialog open={showModal} onClose={closeModal} fullWidth maxWidth="md">
        <div style={{ padding: "40px 30px", position: "relative" }}>
          <IconButton
            style={{ position: "absolute", top: "0", right: "0" }}
            onClick={closeModal}
          >
            <CloseIcon />
          </IconButton>
          <DialogContent style={{ textAlign: "center" }}>
            {selectedEvent && (
              <>
                <Helmet>
                  <title>{selectedEvent.titre}</title>
                </Helmet>
                <h1 style={{ marginBottom: "15px" }}>{selectedEvent.titre}</h1>
                <img
                  src={getImageUrl(selectedEvent.photo)}
                  alt="Selected Event"
                  style={{
                    width: "50%",
                    height: "50%",
                    marginBottom: "20px",
                    boxShadow: "0px 0px 15px rgba(0, 0, 0, 0.2)",
                  }}
                />
                <div style={{ display: "flex", alignItems: "center" }}>
                  <CiCalendarDate className="EvntDate" />
                  <h3 className="EvntDate">{selectedEvent.dates}</h3>
                </div>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <MdGpsFixed className="EvntLieu" />
                  <h3 className="EvntLieu">{selectedEvent.lieu}</h3>
                </div>
                <h5
                  dangerouslySetInnerHTML={{ __html: selectedEvent.texte }}
                  style={{ textAlign: "left", marginTop: "20px" }}
                />
                <h4 style={{ marginTop: "20px", marginBottom: "10px" }}>
                  Partager cet évènement :
                </h4>
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <FacebookShareButton url={shareURL}>
                    <FacebookIcon className="iconFacebook" />
                  </FacebookShareButton>
                  <LinkedinShareButton url={shareURL}>
                    <LinkedInIcon className="iconLinkedin" />
                  </LinkedinShareButton>
                </div>
              </>
            )}
          </DialogContent>
        </div>
      </Dialog>
      <Newsletter />
      <Footer />
    </>
  );
};

export default AjouterEvtt;
