import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { CiCalendarDate } from "react-icons/ci";
import { MdGpsFixed } from "react-icons/md";
import {
  FacebookShareButton,
  LinkedinShareButton,
} from "react-share";
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import FacebookIcon from '@mui/icons-material/Facebook';
import { Helmet } from "react-helmet-async";
import { BASE_URL_back, getImageUrl } from "../../index.js";
import { GetEvenement } from "../../Redux/Slice/EvenementSlice";
import { Evnt_OG } from "../../Redux/Slice/EvenementSlice.js";
import logo from "../../images/logo.jpg";
import "./css/evenement.css";

const EvenementPartage = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const [loadedEvent, setLoadedEvent] = useState(null);

  useEffect(() => {
    dispatch(GetEvenement(id));
  }, [dispatch, id]);

  const { Evenement } = useSelector((state) => state.evenement);
  const event = Evenement.find((evt) => evt._id === id);

  useEffect(() => {
    setLoadedEvent(event);
    if (event) {
      dispatch(Evnt_OG(event._id));
    }
  }, [event, dispatch]);

  const ogTitle = loadedEvent ? loadedEvent.titre : "événement";
  const ogDescription = loadedEvent ? loadedEvent.description : "Description de l'événement";
  const ogImage = loadedEvent ? getImageUrl(loadedEvent.photo) : "";
  const share_Url = loadedEvent ? `${BASE_URL_back}/Evenement/${id}` : '';

  return (
    <>
      <Helmet>
        <title>{ogTitle}</title>
        <meta property="og:title" content={ogTitle} />
        <meta property="og:description" content={ogDescription} />
        <meta property="og:image" content={ogImage} />
        <meta property="og:url" content={share_Url} />
        <meta name="twitter:card" content="summary_large_image" />
      </Helmet>

      <div style={{ padding: "40px" }}>
        <img src={logo} alt="logo" width="220px" height="70" />
        <hr />
        {loadedEvent && (
          <>
            <div>
              <img
                src={getImageUrl(loadedEvent.photo)}
                alt="Event"
                className="Evnt-image"
              />
            </div>
            <div className="modal-Evnt">
              <h2 className="Evnt-titre">{loadedEvent.titre}</h2>
              <div
                className="Evnt-descri"
                dangerouslySetInnerHTML={{ __html: loadedEvent.texte }}
              />
              <div className="Evnt-info">
                <div className="info-item">
                  <CiCalendarDate className="info-icon" />
                  <h5 className="info-date">{loadedEvent.dates}</h5>
                </div>
                <div className="info-item">
                  <MdGpsFixed className="info-icon" />
                  <h5 className="info-lieu">{loadedEvent.lieu}</h5>
                </div>
              </div>
              <div className="partagerEVNT">
                <div>
                  <FacebookShareButton url={share_Url} quote={ogTitle}>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        backgroundColor: "#0965FE",
                        paddingRight: "5px",
                      }}
                    >
                      <FacebookIcon style={{ color: "#fff" }} size={20} />
                      <h3 className="info-item">Partage</h3>
                    </div>
                  </FacebookShareButton>
                </div>
                <div>
                  <LinkedinShareButton url={share_Url}>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        backgroundColor: "#0077B5",
                        paddingRight: "5px",
                      }}
                    >
                      <LinkedInIcon style={{ color: "#fff" }} size={20} />
                      <h3 className="info-item">Partage</h3>
                    </div>
                  </LinkedinShareButton>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default EvenementPartage;
